<template>
	<div>
		<el-dialog
			:title="title"
			:visible.sync="open"
			width="700px"
			@close="commitOnClose"
		>
			<el-form
				ref="saveOrEditForm"
				:model="saveData"
				label-width="150px"
				style="width:600px"
				:rules="rules"
			>
				<el-form-item label="代理商/机构编号" prop="agentNo2">
					<el-select
						style="width:280px"
						v-model="saveData.agentNo2"
						filterable
						remote
						reserve-keyword
						:disabled="!isAdd"
						@change="getUserName"
						placeholder="可根据代理商/机构编号模糊查询"
						:remote-method="remoteMethod"
						:loading="loading"
					>
						<el-option
							v-for="item in options"
							:key="item.id"
							:label="item.agentNo"
							:value="item"
						>
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="代理商/机构名称" prop="userName">
					{{ saveData.userName }}
				</el-form-item>
				<el-form-item label="加收政策" prop="policyNo">
					<el-select
						v-model="saveData.policyNo"
						placeholder="请选择加收政策"
						clearable
						size="small"
						:disabled="!isAdd"
						style="width: 280px"
					>
						<el-option
							v-for="item in policyList"
							:label="item.policyName"
							:value="item.policyNo"
							:key="item.policyNo"
						>
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="优惠时间" prop="freeDays">
					<el-input-number
						:controls="false"
						:precision="0"
						v-model="saveData.freeDays"
						class="num-inp"
						placeholder="请输入优惠时间"
						style="width: 280px"
					></el-input-number>
					天
				</el-form-item>
				<el-form-item label="津贴比例" prop="allowanceCost">
					<el-select
						v-model="saveData.allowanceCost"
						placeholder="请选择津贴比例"
						style="width: 280px"
					>
						<el-option
							v-for="item in rateList"
							:label="item.label"
							:value="item.value"
							:key="item.value"
						>
						</el-option>
					</el-select>
					%
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" @click="submitCheck"
					>确定新增</el-button
				>
				<el-button type="primary" @click="cancel">取消</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
import { FinancialToolsApi, PolicyApi, SpecialApi } from "@/api";
export default {
	name: "CustomForm",
	props: {
		dicts: {
			type: Object,
			default: function() {
				return {};
			}
		},
		isAdd: {
			type: Boolean,
			default: true
		},
		selectRow: {
			type: Object,
			default: function() {
				return {};
			}
		},
		onFresh: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			title: "",
			saveData: {},
			open: false,
			rules: {
				agentNo2: [
					{
						required: true,
						message: "请选择代理/机构编号",
						trigger: "change"
					}
				],
				policyNo: [
					{
						required: true,
						message: "请选择加收政策",
						trigger: "change"
					}
				],
				freeDays: [
					{
						required: true,
						message: "请输入优惠时间",
						trigger: "change"
					}
				],
				allowanceCost: [
					{
						required: true,
						message: "请选择津贴比例",
						trigger: "change"
					}
				]
			},
			options: [],
			agentNo: "",
			loading: false,
			rateList: [],
			policyList: [],
			adjustList: []
		};
	},
	watch: {
		onFresh: function(value) {
			this.open = value;
			if (value) {
				this.resetData();
			}
		}
	},
	mounted() {
		this.adjustList = this.dicts.ADJUST_TYPE;
		for (let i = 1; i <= 100; i++) {
			this.rateList.push({ value: i / 1000, label: i / 1000 });
		}
		PolicyApi.policyInfo.getPolicyList().then(res => {
			if (res.success) {
				this.policyList = this.policyList.concat(res.data);
			}
		});
	},
	methods: {
		async remoteMethod(query) {
			if (query != "") {
				let res = await FinancialToolsApi.suspendOrder.queryAgentInfo(
					query
				);
				if (res.success) {
					this.options = res.data;
					console.log(res.data);
				}
			} else {
				this.options = [];
			}
		},
		submitCheck() {
			this.$refs.saveOrEditForm.validate(valid => {
				if (valid) {
					this.submitForm();
				} else {
					return false;
				}
			});
		},
		async resetData() {
			if (this.isAdd) {
				this.title = "新增";
				this.saveData = {};
			} else {
				this.saveData = this.selectRow;
				this.saveData.agentNo2 = this.saveData.agentNo;
				this.isAdd = false;
				this.title = "修改";
			}
		},
		async submitForm() {
			let result = this.isAdd
				? await SpecialApi.agentAllowanceCost.save(this.saveData)
				: await SpecialApi.agentAllowanceCost.edit(
						this.saveData.id,
						this.saveData
				  );
			if (result.success) {
				this.Message.success(this.isAdd ? "添加成功" : "修改成功");
				this.cancel();
			}
		},
		cancel() {
			this.saveData = {};
			this.saveData.userName = "";
			this.saveData.agentNo = "";
			this.saveData.adjustNo = "";
			this.saveData.rate = "";
			this.saveData.agentNo2 = "";
			this.commitOnClose();
		},
		commitOnClose() {
			this.$emit("on-close");
		},
		getUserName(item) {
			this.saveData.userName = item.userName;
			this.saveData.agentNo = item.agentNo;
		}
	}
};
</script>

<style scoped></style>
